import { createStore } from 'vuex'
let modulesFiles = require.context('./', false, /\.js$/);
let modules = modulesFiles.keys().reduce((modules, modulePath) => {
    const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1');
    if (moduleName == 'index') return { ...modules };
    let obj = {
        [moduleName]: modulesFiles(modulePath).default || { ...modulesFiles(modulePath) }
    };
    return { ...modules, ...obj };
}, {});
export default createStore({
    modules: {
        ...modules
    }
});