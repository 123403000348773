
const cart = {
    namespaced: true,
    state: {
        version: '1.0.0'
    },
    getters: {
        version: state => state.version,
    },
    mutations: {
        version(state, value) {
            state.version = value;
        },
    },
    actions: {
        version(context, payload) {
            context.commit('version', payload);
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    if (payload.version) {
                        context.commit("version", payload.version);
                        resolve('版本更新成功');
                    } else {
                        reject('版本更新失败');
                    }
                }, 2000)
            });
        }
    }
};
export default cart;