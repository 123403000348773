/**
 * 本地缓存
 */
function factory(engine) {
    return {
        set: (key, data) => {
            if (!key) { return; }
            engine.setItem(key, JSON.stringify(data));
        },
        get: (key) => {
            if (!key) { return; }
            let data = engine.getItem(key);
            try {
                return JSON.parse(data);
            } catch (error) {
                return data;
            }
        },
        remove: (key) => {
            if (!key) { return; }
            engine.removeItem(key);
        },
        clear: () => {
            engine.clear();
        }
    }
}

export const localCache = factory(localStorage);
export const sessionCache = factory(sessionStorage);