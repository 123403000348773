import { createWebHashHistory, createRouter } from 'vue-router'

const routes = [
    {
        path: '/',
        component: () => import('@/views/login'),
        meta: {
            title: '登录页'
        },
    },
    {
        path: '/login',
        component: () => import('@/views/login'),
        meta: {
            title: '登录页'
        },
    },
    {
        path: '/wxnet/search',
        component: () => import('@/views/wxnet/search'),
        meta: {
            title: '公众号-搜索页'
        },
    },
    {
        path: '/wxnet/result',
        component: () => import('@/views/wxnet/result'),
        meta: {
            title: '公众号-结果页'
        },
    },
    {
        path: '/home',
        component: () => import('@/views/home'),
        meta: {
            title: '首页',
        },
        children: [{
            path: 'recommendation',
            component: () => import('@/views/home/recommendation'),
            meta: {
                title: '我的团队',
                keepAlive: true
            }
        }, {
            path: 'mine',
            component: () => import('@/views/home/mine'),
            meta: {
                title: '我的',
            }
        }]
    },
    {
        path: '/recommendation/childTeam',
        component: () => import('@/views/home/recommendation/childTeam'),
        meta: {
            title: '下级团队列表',
            keepAlive: true
        }
    },
    {
        path: '/recommendation/childDetail',
        component: () => import('@/views/home/recommendation/childDetail'),
        meta: {
            title: '下级团队详细'
        }
    },
    {
        path: '/mine/invitation',
        component: () => import('@/views/mine/invitation'),
        meta: {
            title: '我的团队码'
        }
    },
    {
        path: '/mine/pearl',
        component: () => import('@/views/mine/pearl'),
        meta: {
            title: '我的珍珠'
        }
    },
    {
        path: '/mine/richtext',
        component: () => import('@/views/mine/richtext'),
        meta: {
            title: '富文本'
        }
    },
    {
        path: '/mine/profit',
        component: () => import('@/views/mine/profit'),
        meta: {
            title: '我的补贴',
        }
    },
    {
        path: '/mine/certification',
        component: () => import('@/views/mine/certification'),
        meta: {
            title: '实名认证'
        }
    },
    {
        path: '/mine/certification/status',
        component: () => import('@/views/mine/certification/status'),
        meta: {
            title: '认证状态'
        }
    },
    {
        path: '/mine/cashout',
        component: () => import('@/views/mine/cashout'),
        meta: {
            title: '结算'
        }
    },
    {
        path: '/mine/cashout/bindaccount',
        component: () => import('@/views/mine/cashout/bindaccount'),
        meta: {
            title: '绑定账号'
        }
    },
    {
        path: '/mine/cashout/cashdetail',
        component: () => import('@/views/mine/cashout/cashdetail'),
        meta: {
            title: '提现明细'
        }
    },
    {
        path: '/mine/level',
        component: () => import('@/views/mine/level'),
        meta: {
            title: '合伙人'
        }
    },
    {
        path: '/mine/setting',
        component: () => import('@/views/mine/setting'),
        meta: {
            title: '设置'
        }
    },
    {
        path: '/mine/setting/password',
        component: () => import('@/views/mine/setting/password'),
        meta: {
            title: '设置交易密码'
        }
    },
    {
        path: '/mine/setting/forgetpass',
        component: () => import('@/views/mine/setting/forgetpass'),
        meta: {
            title: '忘记密码'
        }
    },
    {
        path: '/mine/setting/setpass',
        component: () => import('@/views/mine/setting/setpass'),
        meta: {
            title: '设置密码'
        }
    },
    {
        path: '/mine/setting/updatepass',
        component: () => import('@/views/mine/setting/updatepass'),
        meta: {
            title: '修改密码'
        }
    }
]

const router = createRouter({
    scrollBehavior(to, from, savedPosition) {
        // keep-alive 返回缓存页面后记录浏览位置
        if (savedPosition && to.meta.keepAlive && to.meta.isBack) {
            return savedPosition;
        }
        // 异步滚动操作
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve({ x: 0, y: 0 });
            }, 0);
        });
    },
    history: createWebHashHistory(),
    routes
})

router.afterEach((to) => {  // 一定要再afterEach中判断而不是beforeEach，因为beforeEach在点击返回之后获取到的值不准确，每返回一次，会获取到延后一次的to、history
    if (window.history.state && window.history.state.forward) { // 或者判断 to.forward,window.history.state.forward是vue-router写入的，当返回或前进的时候才会有值
        to.meta.isBack = true;
    } else {
        to.meta.isBack = false;
    }
});

export default router

