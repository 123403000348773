/**
 * 配置Ajax
 */
import axios from 'axios';
import { localCache } from '@/assets/js/cache';
import { Toast } from "vant";
import router from '@/routes'
import { useRouter } from "vue-router";
// import loadingImg from '../image/loading.gif';

export default function () {
    let _prefix = '';
    let _token = '';
    // let requestCount = 0; // 请求数量
    const showLoading = () => {
        // if (requestCount === 0) {
        //     let renderDom = document.createElement('div');
        //     renderDom.setAttribute('id', 'requestLoading');
        //     document.body.appendChild(renderDom);
        //     ReactDOM.render(<Spin size='large' indicator={<img style={{ width: 120, height: 120, userSelect: 'none' }} src={loadingImg} />} ></Spin>, renderDom);
        // }
        // requestCount++;
    }; //显示Spin组件

    const hideLoading = () => {
        // requestCount--;
        // if (requestCount === 0) {
        //     document.body.removeChild(document.getElementById('requestLoading'));
        // }
    };//隐藏Spin组件

    // 根据不同的环境获取响应的api域名地址
    if (process.env.NODE_ENV === 'production') {
        _prefix = `/`;
    } else {
        _prefix = '/';
    }
    axios.defaults.baseURL = _prefix;

    // 请求超时时间为10秒
    axios.defaults.timeout = 10000;

    // 请求发送拦截，并在头部加入token
    axios.interceptors.request.use(
        function (config) {
            let nextToken = localCache.get('auth') && localCache.get('auth').token;
            if (config.url == '/request?cmd=userLogin/v1') { // 如果为登录接口，重置登录信息
                _token = '';
            } else if (_token == '') {  // 第一次登录直接从缓存后去token
                _token = nextToken;
            } else {
                if (_token != nextToken) {
                    _token = '';  // 前后两次调用token不同，退出登录防止串token
                } else {
                    _token = nextToken;
                }
            }
            if (_token) {
                config.headers['token'] = `${_token}`; // 存在将token写入请求头
            }
            if (config.headers.isLoading !== false) showLoading();
            return config;
        },
        function (error) {
            if (error.config.headers.isLoading !== false) hideLoading();
            return Promise.reject(error);
        }
    );

    // 请求结果拦截，根据不同的错误码触发相应操作
    axios.interceptors.response.use(
        function (response) {
            try {
                if (response.data.errCode != 0) {
                    // 以下不合法code跳转登录页
                    if (response.data.errCode == 3001 || response.data.errCode == 3002 || response.data.errCode == 3003 || response.data.errCode == 3004 || response.data.errCode == 3005 || response.data.errCode == 3006 || response.data.errCode == 4007) {
                        // token失效
                        localCache.remove('auth');
                        if (window.globalHistory) {
                            Toast('账号过期或异地登录，请重新登录');
                            window.globalHistory.replace('/login');
                        } else {
                            Toast('账号过期或异地登录，请重新登录');
                            router.replace('/login');
                            // setTimeout(() => { window.location.reload(); }, 2000);
                        }
                    } else {
                        // 1.接口错误，当调登录接口时不弹报错弹框，由登录页捕获显示
                        // 2.接口错误，当为以下错误码时，不弹报错弹框，由页面捕获显示
                        // 6411008 订单信息错误
                        if (response.data.cmd != 'userLogin/v1' && response.data.errCode != 6411008) {
                            if (response.data.errDesc) {
                                Toast(response.data.errDesc);
                            } else if (response.data.errName) {
                                Toast(response.data.errName);
                            } else {
                                Toast('接口' + response.data.errCode + '错误：【' + response.config.url + '】');
                            }
                        }
                    }
                }
            } catch (e) {
                Toast('后台' + response.data.errCode + '错误返回格式不正确');
            }
            if (response.config.headers.isLoading !== false) hideLoading();
            return response;
        },
        function (error) {
            console.log('responseError:', error.response);
            if (error.config.headers.isLoading !== false) hideLoading();
            if (error.response) {
                if (error.response.data.status) {
                    switch (error.response.data.status) {
                        case 4007:
                            // 账号被重复登录
                            Toast('账号过期或异地登录，请重新登录');
                            localCache.remove('auth');
                            if (window.globalHistory) {
                                window.globalHistory.replace('/login');
                            } else {
                                router.replace('/login');
                            }
                            break;
                        case 3003:
                            // 账号被重复登录
                            Toast('账号其它地方被重复登录');
                            localCache.remove('auth');
                            if (window.globalHistory) {
                                window.globalHistory.replace('/login');
                            } else {
                                router.replace('/login');
                            }
                            break;
                        case 3004:
                            // token失效
                            Toast('由于您多点登录或长时间未操作，请重新登录');
                            localCache.remove('auth');
                            if (window.globalHistory) {
                                window.globalHistory.replace('/login');
                            } else {
                                router.replace('/login');
                            }
                            break;
                        case 500:
                            // 500 服务端请求错误
                            Toast('接口500错误：【' + error.response.data.path + '】');
                            return Promise.reject(error.response);
                        case 404:
                            // 找不到资源
                            Toast('接口404错误：【' + error.response.data.path + '】');
                            return Promise.reject(error.response);
                        default:
                            return Promise.reject(error.response);
                    }
                } else {
                    switch (error.response.status) {
                        case 502:
                            Toast('当前系统繁忙，请稍后再试');
                            return Promise.resolve(error.response);
                        case 504:
                            Toast('当前系统繁忙，请稍后再试');
                            return Promise.reject(error.response);
                        default:
                            return Promise.reject(error.response);
                    }
                }
            }
        }
    );
}

// 提供给第三方嵌入系统，实现跳转至登录页
export const junpToLogin = () => {
    history.replace('/login'); // 账号被重复登录
};