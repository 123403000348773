import { createApp } from 'vue'
import App from './App.vue'
import Vant from 'vant'
import 'amfe-flexible'
import 'vant/lib/index.css'
import router from '@/routes'
import ajaxConfig from '@/assets/http/ajaxConfig'
import store from '@/store'
import '@/assets/css/base.scss'
import eventBusConfig from '@/assets/js/eventBus';

const app = createApp(App)
app.config.globalProperties.$version = '1.0.0';
app.use(Vant)
app.use(router)
app.use(store);
app.mount('#app')
ajaxConfig(); // 配置ajax
eventBusConfig(app); // 配置eventBus